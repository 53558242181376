import Axios from "axios";
import { env } from "../env/env";
import { toast } from "react-toastify";
// const API_ROOT = "https://api.ebst.tech";

function buildHeader() {
  const headers = {
    "Accept-Language": "vi",
    "Content-Type": "application/json",
  };
  const token = JSON.parse(localStorage.getItem("accessToken"));

  if (token && token !== "undefined") {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

function buildHeaderWithAuth() {
  const headers = {
    'Accept-Language': "vi",
    'Content-Type': 'multipart/form-data'
  };
  const token = JSON.parse(localStorage.getItem("accessToken"));

  if (token && token !== "undefined") {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

export function buildRequest(method, url, params, data) {
  return Axios({
    method: method || "GET",
    url: `${env.API_ROOT}/${url}`,
    params,
    data,
    headers: buildHeader(),
    timeout: 30000
  });
}

export function buildRequestWithResponeType(method, url, params, data) {
  return Axios({
    method: method || "GET",
    url: `${env.API_ROOT}/${url}`,
    params,
    data,
    headers: buildHeader(),
    responseType: 'blob'
  });
}

export function buildRequestNoContentType(method, url, params, data) {
  return Axios({
    method: method || "GET",
    url: `${env.API_ROOT}/${url}`,
    params,
    data,
    headers: buildHeaderWithAuth(),
  });
}

export function buildRequestFormData(method, url, params, data) {
  return Axios({
    method: method || "POST",
    url: `${env.API_ROOT}/${url}`,
    params,
    data,
    headers: {
      ...buildHeaderWithAuth(),
      'Content-Type': 'multipart/form-data'
    },
  });
}

export const transformResponse = (res) => {
  return Promise.resolve(res);
};

export const refreshTokenAPI = async () => {
  const token = JSON.parse(localStorage.getItem("accessToken"));
  if (!token) {
    throw new Error("Refresh token not found");
  } else {
    var resuilt = await post(`Auths/RefreshToken`);

    if (resuilt?.data?.message === "Success") {
      localStorage.setItem(
        "accessToken",
        JSON.stringify(resuilt?.data?.data?.access_token)
      );
    }
  }
};

["post", "put", "delete"].forEach((method) => {
  Axios.defaults.headers[method]["Content-Type"] =
    "application/json; charset=utf-8";
});

function handleRequest(request, noToast = false) {
  return request()
    .catch((err) => {
      const res = err.response;
      if (res?.status === 401) {
        return refreshTokenAPI()
          .then(() => request())
          .catch((error) => {
            localStorage.clear();
            window.location.replace("/login");
          });
      }
      if (res?.status === 500 && !noToast) {toast.error(res?.data?.error)}
      if (res?.status === 400 && !noToast) {toast.error(res?.data?.message)}
      return err.response;
    })
    .then(transformResponse);
}

export const get = (...params) =>
  handleRequest(() => buildRequest("GET", ...params)).then((res) => {
    return res;
  });

export const post = (url, data, noToast = false) =>
  handleRequest(() => buildRequest("POST", url, null, data), noToast).then((res) => {
    return res;
  });

export const put = (url, data, noToast = false) =>
  handleRequest(() => buildRequest("PUT", url, null, data), noToast).then((res) => {
    return res;
  });

export const del = (...params) =>
  handleRequest(() => buildRequest("DELETE", ...params)).then((res) => {
    return res;
  });
export const delImage = (url, data) =>
  handleRequest(() => buildRequest("DELETE", url, null, data)).then((res) => {
    return res;
  });

export const postUploadImage = (url, data) =>
  handleRequest(() => buildRequestNoContentType("POST", url, null, data)).then((res) => {
    return res;
  });

export const downloadFile = (...params) =>
  handleRequest(() => buildRequestWithResponeType("GET", ...params)).then((res) => {
    return res;
  });

export const putFormData = (url, data) =>
  handleRequest(() => buildRequestFormData("PUT", url, null, data)).then((res) => {
    return res;
  });